<template>
  <span v-if="isJalaali">
    {{ value | jMoment(format) | persianNumber() }}
  </span>
  <span v-else>
    {{ value }}
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Date]
    },
    isJalaali: {
      type: Boolean,
      default: false
    },
    format: {
      type: String,
      default: "jYYYY/jMM/jD ساعت HH:mm:ss"
    }
  }
};
</script>

<style lang="scss" scoped></style>
