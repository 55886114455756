<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="options"
    :server-items-length="total"
    :loading="loading"
    class="elevation-1"
    :footer-props="{
      showFirstLastPage: true,
      itemsPerPageOptions: [10, 15, 20, 30, 50, 100]
    }"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    multi-sort
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title>{{
          $vuetify.lang.t(`$vuetify.routes.${$route.name}`)
        }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn v-if="filtersKey.length" icon color="red" @click="clearFilters">
          <v-icon>mdi-filter-remove-outline</v-icon>
        </v-btn>
        <v-dialog scrollable v-model="filterDialog" max-width="800px">
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon> mdi-filter-outline </v-icon>
            </v-btn>
          </template>
          <v-form v-model="valid" @submit.prevent="filter">
            <v-card>
              <v-card-title>
                <span class="headline">{{
                  $vuetify.lang.t(`$vuetify.filters`)
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row align="center">
                    <slot
                      v-for="field in fields.filter(a =>
                        a.value !== 'actions' && a.filterable === undefined
                          ? true
                          : a.filterable
                      )"
                      :name="`filter-field:${field.value}`"
                      :value="filters[field.value]"
                      :fieldModel="field"
                      :change="value => (filters[field.value] = value)"
                    >
                      <v-col
                        :cols="
                          (field.filterGrid && field.filterGrid.cols) ||
                            (field.inputGrid && field.inputGrid.cols) ||
                            12
                        "
                        :sm="
                          (field.filterGrid && field.filterGrid.sm) ||
                            (field.inputGrid && field.inputGrid.sm)
                        "
                        :md="
                          (field.filterGrid && field.filterGrid.md) ||
                          (field.inputGrid && field.inputGrid.md) ||
                          field.type === Date
                            ? 12
                            : 6
                        "
                        :lg="
                          (field.filterGrid && field.filterGrid.lg) ||
                            (field.inputGrid && field.inputGrid.lg)
                        "
                        :xl="
                          (field.filterGrid && field.filterGrid.xl) ||
                            (field.inputGrid && field.inputGrid.xl)
                        "
                        :key="field.value"
                        class="py-2"
                      >
                        <filterFeilds
                          v-model="filters[field.value]"
                          :label="field.text"
                          :fieldModel="field"
                          :default="field.default"
                        />
                      </v-col>
                    </slot>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="filterDialog = false">
                  {{ $vuetify.lang.t(`$vuetify.cancel`) }}
                </v-btn>
                <v-btn
                  :disabled="!valid && editLoading"
                  color="blue darken-1"
                  text
                  type="submit"
                  :loading="editLoading"
                >
                  {{ $vuetify.lang.t(`$vuetify.apply`) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog
          scrollable
          v-model="editDialog"
          v-if="selectedItems.length <= 1 && (editable || addable)"
          max-width="800px"
        >
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon v-if="selectedItems.length === 1 && editable">
                mdi-pencil
              </v-icon>
              <v-icon v-else-if="addable"> mdi-plus </v-icon>
            </v-btn>
          </template>
          <v-form v-model="valid" @submit.prevent="save">
            <v-card>
              <v-card-title>
                <span class="headline">{{
                  isAdd
                    ? $vuetify.lang.t(`$vuetify.${model.i18nPath}.headers.add`)
                    : $vuetify.lang.t(`$vuetify.${model.i18nPath}.headers.edit`)
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row align="center">
                    <slot
                      v-for="field in fields.filter(
                        a =>
                          a.value !== 'actions' &&
                          (editedIndex === -1
                            ? a.addable === undefined
                              ? true
                              : a.addable
                            : a.editable === undefined
                            ? true
                            : a.editable)
                      )"
                      :name="`input-field:${field.value}`"
                      :value="editedItem[field.value]"
                      :item="editedItem"
                      :fieldModel="field"
                      :change="value => (editedItem[field.value] = value)"
                    >
                      <v-col
                        :cols="(field.inputGrid && field.inputGrid.cols) || 12"
                        :sm="field.inputGrid && field.inputGrid.sm"
                        :md="(field.inputGrid && field.inputGrid.md) || 6"
                        :lg="field.inputGrid && field.inputGrid.lg"
                        :xl="field.inputGrid && field.inputGrid.xl"
                        :key="field.value"
                        class="py-2"
                      >
                        <inputFields
                          v-model="editedItem[field.value]"
                          :label="field.text"
                          :fieldModel="field"
                          :default="field.default"
                        />
                      </v-col>
                    </slot>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editDialog = false">
                  {{ $vuetify.lang.t(`$vuetify.cancel`) }}
                </v-btn>
                <v-btn
                  :disabled="!valid && editLoading"
                  color="blue darken-1"
                  text
                  type="submit"
                  :loading="editLoading"
                >
                  {{ $vuetify.lang.t(`$vuetify.save`) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <slot name="removeDialog">
          <v-dialog v-model="removeConfirm" max-width="290">
            <v-card>
              <v-card-title class="headline">
                {{ $vuetify.lang.t(`$vuetify.remove`) }}
              </v-card-title>

              <v-card-text>
                {{ $vuetify.lang.t(`$vuetify.removeMessage`) }}
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text @click="removeConfirm = false">
                  {{ $vuetify.lang.t(`$vuetify.cancel`) }}
                </v-btn>

                <v-btn
                  color="red darken-1"
                  text
                  @click="confrimRemove"
                  :disabled="editLoading"
                  :loading="editLoading"
                >
                  {{ $vuetify.lang.t(`$vuetify.remove`) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </slot>
      </v-toolbar>
      <!-- TODO filter chips -->
      <!-- <v-row class="ma-0" align="center" v-if="filtersKey.length">
        <v-col cols="auto" class="py-0">
          <v-btn icon small>
            <v-icon small>mdi-filter-remove-outline</v-icon>
          </v-btn>
        </v-col>
        <v-col class="pa-0">
          <v-chip-group>
            <v-chip v-for="key in filtersKey" :key="key" close small>
              {{ model.schema[key] }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row> -->
    </template>
    <template
      v-for="header in headers"
      v-slot:[`item.${header.value}`]="{ item }"
    >
      <!-- action field -->
      <template v-if="header.value === 'actions'">
        <slot name="actions" :item="item">
          <v-btn
            small
            icon
            :disabled="!!getItemLoader"
            :loading="getItemLoader === item._id"
            @click="editItem(item)"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn small icon @click="deleteItem(item)">
            <v-icon small color="red"> mdi-delete </v-icon>
          </v-btn>
        </slot>
      </template>
      <!-- other field -->
      <template v-else>
        <slot
          :name="`field:${header.name}`"
          :value="item[header.value]"
          :item="item"
        >
          <feilds
            :value="item[header.value]"
            :item="item"
            :schema="header"
            :type="header.type"
          >
          </feilds>
        </slot>
      </template>
    </template>
  </v-data-table>
</template>

<script>
import Bread from "@/services/bread";
import { deepEqual } from "fast-equals";
import feilds from "./fields";
import inputFields from "./inputFields";
import filterFeilds from "./filterFields";
import models from "@/models";
import { getValue } from "@/tools/functions";
export default {
  name: "data-table",
  props: {
    modelName: {
      type: String,
      required: true,
      validator: function(name) {
        return !!models[name];
      }
    },
    noActions: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    filterDialog: false,
    editDialog: false,
    editLoading: false,
    removeConfirm: false,
    getItemLoader: undefined,
    total: 0,
    valid: false,
    items: [],
    selectedItems: [],
    filters: {},
    editedIndex: -1,
    options: {
      page: 1,
      itemsPerPage: 15
    },
    editedItem: {},
    loading: false,
    sortBy: [],
    sortDesc: []
  }),
  computed: {
    filtersKey() {
      return Reflect.ownKeys(this.$route.query).filter(
        key => this.$route.query[key]
      );
    },
    model() {
      return models[this.modelName];
    },
    hasFilter() {
      return false;
    },
    fields() {
      const _fields = Reflect.ownKeys(this.model.schema)
        .sort(
          (a, b) =>
            (this.model.schema[a].order || Infinity) -
            (this.model.schema[b].order || Infinity)
        )
        .filter(
          key =>
            key !== "__ob__" &&
            !getValue(this.model.schema[key].hide, this.editedItem)
        )
        .map(key => ({
          text: this.$vuetify.lang.t(
            `$vuetify.${this.model.i18nPath}.${this.model.schema[key].name ||
              key}`
          ),
          value: key,
          ...this.model.schema[key]
        }));
      return this.$props.noActions
        ? _fields
        : [
            ..._fields,
            {
              text: this.$vuetify.lang.t(
                `$vuetify.${this.model.i18nPath}.actions`
              ),
              value: "actions",
              sortable: false
            }
          ];
    },
    headers() {
      return this.fields.filter(field => !field.hideInBrowse);
    },
    isAdd() {
      return this.editedIndex === -1;
    },
    addable() {
      return this.model.addable ?? true;
    },
    editable() {
      return this.model.editable ?? true;
    }
  },

  components: {
    feilds,
    inputFields,
    filterFeilds
  },
  watch: {
    "$route.query"() {
      this.filters = { ...this.filters, ...this.$route.query };
      this.getItems();
    },
    options: {
      handler() {
        this.getItems();
      },
      deep: true
    },
    editDialog(val) {
      if (!val) {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }
    }
  },

  created() {
    this.filters = { ...this.filters, ...this.$route.query };
    this.bread = new Bread(models[this.$props.modelName].path);
  },

  methods: {
    save() {
      if (this.editLoading) return;
      this.editLoading = true;
      if (this.isAdd) {
        this.bread
          .add(this.editedItem)
          .then(() => {
            this.editDialog = false;
            this.getItems();
          })
          .finally(() => {
            this.editLoading = false;
          });
      } else {
        this.bread
          .edit(this.items[this.editedIndex]._id, this.editedItem)
          .then(res => {
            this.editDialog = false;
            this.items[this.editedIndex] = res.data;
            this.items = [...this.items];
            if (
              Reflect.ownKeys(this.filters).filter(key => key !== "__ob__")
                .length
            ) {
              this.getItems();
            }
          })
          .finally(() => {
            this.editLoading = false;
          });
      }
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.removeConfirm = true;
    },
    confrimRemove() {
      this.editLoading = true;
      // const item = { ...this.items[this.editedIndex] };
      this.bread
        .delete(this.items[this.editedIndex]._id)
        .then(() => {
          this.getItems();
          this.removeConfirm = false;
          // this.$store.dispatch("global/showSnackbar", {
          //   text: this.$vuetify.lang.t(`$vuetify.dataTable.removeSuccessfully`),
          //   color: "#333",
          //   timeout: 15000,
          //   linkText: this.$vuetify.lang.t(`$vuetify.undo`),
          //   btnClick: () => this.undoRemove(item)
          // });
        })
        .finally(() => {
          this.editLoading = false;
        });
    },
    undoRemove(item) {
      this.editLoading = true;
      this.bread
        .edit(item._id, item)
        .then(() => {
          this.getItems();
        })
        .finally(() => {
          this.editLoading = false;
        });
    },
    async editItem(item) {
      this.getItemLoader = item._id;
      this.editedIndex = this.items.indexOf(item);
      let newItem;
      try {
        newItem = (await this.bread.read(item._id)).data;
      } catch {
        newItem = item;
      }
      this.getItemLoader = undefined;
      this.editedItem = Reflect.ownKeys(newItem)
        .filter(
          key =>
            key !== "__ob__" &&
            this.model.schema[key] &&
            (this.model.schema[key].editable === undefined
              ? true
              : this.model.schema[key].editable)
        )
        .reduce((obj, key) => ({ ...obj, [key]: item[key] }), {});
      this.editDialog = true;
    },
    pushQuery() {
      if (!deepEqual(this.filters, this.$route.query)) {
        this.$router
          .replace({ ...this.$route, query: this.filters })
          .catch(() => {});
      }
    },
    createQuery() {
      return Reflect.ownKeys(this.filters)
        .filter(key => key !== "__ob__")
        .map(field => {
          if (this.filters[field] === undefined) return undefined;
          switch (this.model.schema[field].type) {
            case Date:
            case Number:
              return {
                field,
                operator: this.model.schema[field].filterOperator || "bt",
                value: Array.isArray(this.filters[field])
                  ? this.filters[field].map(a => +a)
                  : [+this.filters[field], null]
              };
            case Array:
              return {
                field,
                operator: this.model.schema[field].filterOperator || "eq",
                value: this.filters[field]
              };
            default:
              return {
                field,
                operator: this.model.schema[field].filterOperator || "eq",
                value: [this.filters[field]]
              };
          }
        })
        .filter(key => key);
    },
    filter() {
      this.pushQuery();
      this.filterDialog = false;
    },
    clearFilters() {
      this.filters = {};
      this.pushQuery();
    },
    getItems() {
      const { page, itemsPerPage } = this.options;
      this.loading = true;
      const query = this.createQuery();
      this.bread
        .browse(
          {
            query,
            sort: this.sortBy.map((field, index) => ({
              field,
              operator: this.sortDesc[index] ? "des" : "asc"
            }))
          },
          page,
          itemsPerPage
        )
        .then(res => {
          this.items = res.data.items;
          this.total = res.data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
