<template>
  <div
    class="pa-1 text-center rounded"
    :style="{ backgroundColor: value, color }"
  >
    {{ value }}
  </div>
</template>

<script>
import { calcTextColor } from "@/tools/functions";
export default {
  props: {
    value: {
      type: String
    }
  },
  computed: {
    color() {
      return calcTextColor(this.value);
    }
  }
};
</script>

<style lang="scss" scoped></style>
