<template>
  <dateField
    v-if="type === Date"
    :value="value"
    :isJalaali="schema.showInJalaali"
    :format="schema.format"
  ></dateField>
  <booleanField
    v-else-if="type === Boolean"
    :value="value"
    :icons="schema.icons"
    :iconColors="schema.iconColor"
  >
  </booleanField>
  <more-text :key="realKey" v-else-if="type === Array">
    <template v-if="schema.persianNumber">
      {{
        schema.i18nPath
          ? (schema.itemText
              ? value.map(v =>
                  this.$vuetify.lang.t(
                    `$vuetify.${schema.i18nPath}.${
                      typeof schema.itemText === "string"
                        ? v[schema.itemText]
                        : schema.itemText(v)
                    }`
                  )
                )
              : this.$vuetify.lang.t(`$vuetify.${schema.i18nPath}.${value}`)
            ).join(schema.joinChar || ", ")
          : (schema.itemText
              ? value.map(v =>
                  typeof schema.itemText === "string"
                    ? v[schema.itemText]
                    : schema.itemText(v)
                )
              : value
            ).join(schema.joinChar || ", ") | persianNumber()
      }}
    </template>
    <template v-else>
      {{
        schema.i18nPath
          ? (schema.itemText
              ? value.map(v =>
                  this.$vuetify.lang.t(
                    `$vuetify.${schema.i18nPath}.${
                      typeof schema.itemText === "string"
                        ? v[schema.itemText]
                        : schema.itemText(v)
                    }`
                  )
                )
              : this.$vuetify.lang.t(`$vuetify.${schema.i18nPath}.${value}`)
            ).join(schema.joinChar || ", ")
          : (schema.itemText
              ? value.map(v =>
                  typeof schema.itemText === "string"
                    ? v[schema.itemText]
                    : schema.itemText(v)
                )
              : value
            ).join(schema.joinChar || ", ")
      }}
    </template>
  </more-text>
  <color
    v-else-if="schema.subType && schema.subType === 'color'"
    :value="value"
  />
  <more-text :key="realKey" v-else-if="schema.ref">
    <template v-if="schema.persianNumber">
      {{
        schema.i18nPath
          ? schema.itemText
            ? this.$vuetify.lang.t(
                `$vuetify.${schema.i18nPath}.${
                  typeof schema.itemText === "string"
                    ? value[schema.itemText]
                    : schema.itemText(value)
                }`
              )
            : this.$vuetify.lang.t(`$vuetify.${schema.i18nPath}.${value}`)
          : schema.itemText
          ? typeof schema.itemText === "string"
            ? value[schema.itemText]
            : schema.itemText(value)
          : value
      }}
    </template>
    <template v-else>
      {{
        schema.i18nPath
          ? this.$vuetify.lang.t(
              `$vuetify.${schema.i18nPath}.${
                typeof schema.itemText === "string"
                  ? value[schema.itemText]
                  : schema.itemText(value)
              }`
            )
          : typeof schema.itemText === "string"
          ? value[schema.itemText]
          : schema.itemText(value)
      }}
    </template>
  </more-text>
  <span v-else>
    <more-text :key="realKey" v-if="schema.persianNumber">
      {{
        schema.i18nPath
          ? this.$vuetify.lang.t(`$vuetify.${schema.i18nPath}.${value}`)
          : value | persianNumber()
      }}
    </more-text>
    <more-text :key="realKey" v-else>
      {{
        schema.i18nPath
          ? this.$vuetify.lang.t(`$vuetify.${schema.i18nPath}.${value}`)
          : value
      }}
    </more-text>
  </span>
</template>

<script>
import dateField from "./date";
import booleanField from "./boolean";
import color from "./color";
import Color from "./color.vue";
import MoreText from "./moreText.vue";
export default {
  props: {
    value: {
      required: true
    },
    item: {
      type: Object
    },
    schema: {
      type: Object,
      required: true
    },
    type: {
      required: true
    }
  },
  data: function() {
    return {
      realKey: this.item._id + new Date().getTime()
    };
  },
  watch: {
    item: {
      handler(val) {
        this.realKey = val._id + new Date().getTime();
      },
      deep: true
    }
  },
  components: {
    dateField,
    booleanField,
    color,
    MoreText
  }
};
Color;
</script>
