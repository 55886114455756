<template>
  <v-icon
    v-if="iconColor && icons"
    :color="value ? iconColor[0] : iconColor[1]"
  >
    {{ value ? icons[0] : icons[1] }}
  </v-icon>
  <v-icon v-else-if="icons" :color="value ? `success` : `error`">
    {{ value ? icons[0] : icons[1] }}
  </v-icon>
  <span v-else>
    {{ value }}
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean
    },
    icons: {
      type: Array
    },
    iconColor: {
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped></style>
