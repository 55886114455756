<template>
  <span>
    {{ text }}
    <v-dialog v-if="isLong" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small icon v-bind="attrs" v-on="on">
          <v-icon small>mdi-more</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-text>
          {{ value }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    }
  },
  computed: {
    isLong() {
      return this.value.length > 100;
    },
    text() {
      return this.value.substr(0, 100);
    },
    value() {
      return this.$slots.default.reduce((prev, node) => prev + node.text, "");
    }
  }
};
</script>

<style lang="scss" scoped></style>
